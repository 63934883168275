import React from "react"
import { graphql } from "gatsby"
import Layout from "@yumgmbh/gatsby-theme-yum-components/src/components/modules/Layout"
import TabFolderBlock from "@yumgmbh/gatsby-theme-yum-components/src/components/blocks/FolderBlock/TabFolderBlock"
import HeadlineBlock from "../components/blocks/HeadlineBlock"
import BodyBlocks from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BodyBlocks"

const Downloadcenter = ({ data }) => {
  const { pageData, entries, allCategories } = data

  const pageContent = JSON.parse(pageData.content)

  const categories = allCategories?.nodes?.map((category) => {
    const name = category.name
    const value = category.slug

    const content = entries?.nodes
      ?.filter((entry) => {
        const parsedContent = JSON.parse(entry.content)

        if (parsedContent.categorie === category?.uuid) {
          return parsedContent
        }
        return null
      })
      .map((entry) => {
        const content = JSON.parse(entry.content)
        return {
          content,
        }
      })
    return {
      title: name,
      value: value,
      component: "downloads_block",
      content: [
        {
          download_items: content.map((item) => item.content),
          component: "downloads_block",
        },
      ],
    }
  })

  return (
    <div>
      <Layout metadata={pageContent.metadata}>
        {/* <Breadcrumbs breadcrumbs={breadcrumbs}></Breadcrumbs> */}
        <HeadlineBlock
          block={{
            headline_level: "h1",
            kicker: pageContent.kicker,
            headline: pageContent.headline,
            subheadline: pageContent.subheadline,
          }}
        />
        <TabFolderBlock block={{ folder_items: categories }} />
        <BodyBlocks body={pageContent.body} />
      </Layout>
    </div>
  )
}

export const data = graphql`
  {
    pageData: storyblokEntry(full_slug: { eq: "downloadcenter" }) {
      name
      content
      full_slug
    }
    entries: allStoryblokEntry(
      filter: { field_component: { eq: "download_entry" } }
      sort: { fields: first_published_at, order: DESC }
    ) {
      nodes {
        name
        content
        tag_list
      }
    }
    allCategories: allStoryblokEntry(
      filter: { field_component: { eq: "category_entry" } }
    ) {
      nodes {
        name
        slug
        uuid
      }
    }
  }
`

export default Downloadcenter
